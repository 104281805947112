.pagination.style-1 {
    display: flex;
    list-style: none;
    padding-left: 0;
    border-radius: 0.25rem;
  }
  
  .pagination.style-1 .page-item {
    margin: 0 8px; 
  }
  
  .pagination.style-1 .page-item .page-link {
    color: #007bff;
    background-color: #f8f9fa; 
    border: 1px solid #ced4da; 
    padding: 8px 16px;
    border-radius: 0.5rem; 
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  }
  
  .pagination.style-1 .page-item .page-link:hover {
    color: #fff;
    background-color: #007bff; 
    border-color: #007bff;
    text-decoration: none;
  }
  
  .pagination.style-1 .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-image: linear-gradient(45deg, #007bff, #0056b3);
    border-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); 
  }
  
  .pagination.style-1 .page-item.disabled .page-link {
    color: #adb5bd;
    pointer-events: none;
    background-color: #f8f9fa;
    border-color: #ced4da;
    opacity: 0.6; 
  }
  