@media (min-width: 1201px) {
  .header-m-number .fs-4 {
    font-size: 16px !important;
  }

  /* Styles for extra large screens */
}

@media (min-width: 1080px) and (max-width: 1300px) {
  /* Styles for larger desktops */
 
   .t_ser_slide .slick-slider  .slide-content.fs-4{
    font-size: 12px !important;
    line-height: 0;
   }
}

@media (min-width: 1025px) {
  .desktop-header {
    display: block;
  }
  .mobile-header {
    display: none;
  }
  
}
@media (max-width: 1024px) {
  .nav-item:hover .dropdown-content{
    position: fixed;
  }
  /* Styles for tablets in landscape or small desktops */
  /* .carousel .slider-wrapper{
        height: auto !important;
    } */
  section {
    overflow: hidden;
  }
  header {
    overflow: hidden;
  }
  .image-content {
    flex-wrap: wrap;
  }
  /* .navbar-list li > *{
      font-size: 12px !important;
    } */
  .desktop-header {
    display: none;
  }
  .mobile-header {
    display: block;
  }
  header .fs-4 {
    font-size: 12px !important;
  }
  .latest-blog {
    flex-wrap: wrap;
    justify-content: center;
  }
  .banner .banner-content {
    margin-bottom: 30px;
  }
}
@media (min-width:768px){
   .m-view {
    display: none !important;
  }.d-view{
    display: block !important;
  }
}

@media (max-width: 767px) {
  /* Styles for tablets */
  .padding-section{
    padding: 40px 0;
  }
  .top-header_ .fs-4 {
    font-size: 9px !important;
  }
  .count_dow_par img.d-sm-none {
    display: none;
  }
  .carousel .slider-wrapper {
    height: 100% !important;
  }
  .d-view {
    display: none !important;
  }
  .count_dow_par .overlay-text.d-sm-none {
    display: none;
  }
  #countdown .time-box span:nth-child(2) {
    display: none;
  }
  .count_dow_par .overlay-text > * {
    color: #fff;
  }
  .count_dow_par .overlay-text h1 {
    font-size: 1.2rem;
  }
  .count_dow_par .overlay-text {
    top: 28%;
  }
  .product-remove{
    position: unset !important;
  }
  .shop-table td.product-remove{
    width: 100% !important;
  }
  .single-product .quick-modal-swiper .swiper-wrapper .swiper-slide,
  .quick-modal-swiper{
    height: auto !important;
  }
}
@media(width < 768px)
{
    .desktop-image {
        display: none;
    }
}
@media( width >= 768px)
{
    .mobile-image
    {
        display: none;
    }
}

@media (max-width: 480px) {
  /* Styles for mobile phones */
  .header-m-number {
    display: none;
  }
  .top-header_ .mx-3{
    margin-left: .9rem !important;
    margin-right: .9rem !important;
  }
}
