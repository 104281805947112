/* Container for order details section */
.account-wrapper {
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  /* Order card styling */
  .order-details {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  /* Header section inside the order card */
  .order-head {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .head-thumb img {
    width: 60px;
    height: auto;
  }
  
  .order-head .clearfix {
    margin-left: 20px;
  }
  
  /* Badge styling based on order status */
  .badge {
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  /* Shipping tracker detail styling */
  .shiping-tracker-detail {
    margin-bottom: 15px;
  }
  
  .shiping-tracker-detail span {
    font-size: 14px;
    color: #6c757d;
  }
  
  .shiping-tracker-detail .title {
    font-size: 16px;
    font-weight: 600;
  }
  
  /* Table styling for item details */
  .table {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .table thead {
    background-color: #343a40;
    color: #ffffff;
  }
  
  .table thead th {
    padding: 10px;
    text-align: center;
  }
  
  .table tbody tr {
    border-bottom: 1px solid #dee2e6;
  }
  
  .table tbody td {
    padding: 10px;
    text-align: center;
  }
  
  .table .text-success {
    color: #28a745;
  }
  
  /* Total amount section styling */

  .d-flex.justify-content-between {
    font-size: 16px;
    font-weight: 600;
    /* padding: 10px 80px; */
  }
  
  /* Styling for buttons */
  .btnhover20 {
    transition: all 0.3s ease-in-out;
  }
  
  .btnhover20:hover {
    transform: scale(1.05);
  }
  
  .btn-outline-primary {
    border-color: #007bff;
    color: #007bff;
  }
  
  .btn-outline-primary:hover {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .btn-outline-danger {
    border-color: #dc3545;
    color: #dc3545;
  }
  
  .btn-outline-danger:hover {
    background-color: #dc3545;
    color: #ffffff;
  }
  
  /* Style adjustments for smaller screens */
  @media (max-width: 576px) {
    .order-head .clearfix {
      margin-left: 10px;
    }
  
    .table thead th, .table tbody td {
      font-size: 12px;
      padding: 8px;
    }
  
    .d-flex.justify-content-between {
      font-size: 14px;
    }
  
    .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .address-section {
    border: 1px solid #dee2e6;
    padding: 15px;
    border-radius: 5px;
    background-color: #f8f9fa;
  }
  
  .address-section h5 {
    margin-bottom: 10px;
  }
  
  .address-detail p {
    margin: 5px 0;
  }
  

  .sing_filter .wrapper .price-input{
    margin-top: 20px;
  }