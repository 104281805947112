.product-preview-image .swiper-slide img{
    width: 100%;
}

.single-product .quick-modal-swiper .swiper-wrapper .swiper-slide{
   border: none !important;
}
.sing_prod_card{
    position: relative;
}
.prdct_card_par .sing_prod_card .s_p_c_hov_con{
    position: relative;
}
.gap-row div:first-child{
    margin-bottom: 10px;
}
.product-wrapper .product-grid .product{
    flex-direction: column;
}
.productlist-sec-7 .product{
    flex-direction: column;
    align-items: normal;
}
.price-input{
    column-gap: 20px;
}
body {
    font-family: "Cabin" !important;
  }
  
  .fw-semibold {
    margin-bottom: 0px;
  }

  .blog-list-m {
    margin: 10px 0;
  }