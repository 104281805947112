
.section_9 {
    position: relative;
    text-align: center;
    color: white;
  }

  .section_9 .container-fluid {
    position: relative;
  }

  .section_9 img {
    width: 100%;
  }

  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .overlay-text h1, .overlay-text h2, .overlay-text p {
    margin: 10px 0;
  }

  #countdown {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .time-box {
    background: rgb(0 0 0 / 8%);
    padding: 10px;
    border-radius: 5px;
  }

  .time-box span {
    display: block;
    font-size: 20px;
  }

.sale-name {
    position: absolute;
    top: 40%;
    left:35%
}
.product-style2{
  height: 50vh;
}