
.account-sidebar-wrapper {
    position: sticky;
    top: 0;
  }
  
  .account-sidebar {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
 
  .profile-head {
    text-align: center;
  }
  
  .profile-head .title {
    font-weight: bold;
    font-size: 1.25rem;
  }
  
  .profile-head .text {
    display: block;
    font-size: 0.875rem;
  }
  
  
  .link-hover {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .link-hover:hover {
    color: #fff; 
    width: 100%;
    background-color: #007bff;
    text-decoration: none;
  }
  
  
  .toggle-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
  
  .toggle-info .title {
    font-weight: bold;
  }
  

  .account-sidebar-wrapper .account-sidebar {
    max-height: 80vh;
    overflow-y: auto;
  }
  
 
  .account-sidebar .d-flex {
    padding-left: 10px;
  }
  
  .account-sidebar .d-flex a {
    margin-bottom: 10px;
    font-size: 1rem;
  }
  .link-hover {
    display: block;
    width: 100%;
    padding: 10px 15px; 
    text-decoration: none; 
    color: inherit; 
  }
  
  .link-hover:hover {
    background-color: #f0f0f0; 
    color: red;
    transition: background-color 0.3s; 
  }
  

  